<template lang="pug">
BasePane(:savedStyle="false")
  SmartHeadlineGeneration.sidebar-spacing-y(v-if="isSmartHeadlineFeatureEnabled" dsAllowed)
  Accordion.mt-3(fix)
    template(#title)
      .font-weight-bold {{ $t('style') }}
    template(v-if="isUniversal")
      CustomThemeStyle(dsAllowed dropdownStyle)
      MoreOrLess
        Shadow(
          :labelOverride="$t('textShadow')"
          v-model="textShadow"
          coloringProperty="desktop.textShadow.color"
          typeOfComponent="text"
          textShadow
        )
        RangeInput(
          :label="$t('textRotation')"
          :min="0"
          :max="360"
          :step="1"
          unit="°"
          v-model="textRotation"
        )
    template(v-else)
      Shadow(
        :labelOverride="$t('textShadow')"
        v-model="textShadow"
        coloringProperty="desktop.textShadow.color"
        typeOfComponent="text"
        textShadow
      )
      RangeInput(
        :label="$t('textRotation')"
        :min="0"
        :max="360"
        :step="1"
        unit="°"
        v-model="textRotation"
      )
  hr.sidebar-spacing-y
  template(v-if="mobilePreview")
    Accordion(fix)
      template(#title)
        .font-weight-bold.mb-3 {{ $t('textSettings') }}
      RangeInput(:label="$t('fontSize')" :min="0" :max="200" :step="1" unit="%" v-model="fontSize")
      AlignDropdown(v-model="alignment" layout="col")
  hr.sidebar-spacing-y(v-if="mobilePreview")
  Accordion(fix)
    template(#title)
      .font-weight-bold.mb-3 {{ $t('spacing') }}
    input-group-control(
      :label="$t('margin')"
      :keysWithValue="marginKeys"
      :allKeysToggled="allMarginKeysToggled"
      :placeholders="marginPlaceholders"
      @all-keys-change="handleMarginAllSideChange"
      @input="handleMarginChange"
      editMobile
    )
  hr.sidebar-spacing-y
  template(#advancedContent)
    InputGroupControl.sidebar-spacing-top(
      :label="$t('padding')"
      :keysWithValue="paddingKeys"
      :allKeysToggled="allKeysToggled"
      :placeholders="paddingPlaceholders"
      @all-keys-change="handlePaddingAllSideChange"
      @input="handlePaddingChange"
      editMobile
    )
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
    OmColorInput(
      :label="$t('background')"
      property="$device.background.color"
      typeOfComponent="text"
      layout="col"
      editMobile
      dsAllowed
    )
    Border(typeOfComponent="text")
    CornerRadius
    Shadow(
      v-model="shadow"
      coloringProperty="$device.shadow.color"
      typeOfComponent="text"
      editMobile
    )
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import MoreOrLess from '@/components/Editor/MoreOrLess.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import InputGroupControl from '@/components/Editor/Controls/InputGroup.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import AlignDropdown from '@/components/Editor/Controls/Alignments/AlignDropdown.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import { SMART_HEADLINE_GENERATOR } from '@/utils/features';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      Accordion,
      MoreOrLess,
      Heading,
      Dropdown,
      RangeInput,
      InputGroupControl,
      Border,
      CornerRadius,
      Shadow,
      AlignDropdown,
      BasePane,
      Hide,
      SmartHeadlineGeneration: () =>
        import('@/editor/components/sidebar/components/SmartHeadlineGeneration.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },

    computed: {
      ...mapState(['mobilePreview']),
      ...mapGetters(['isUniversal', 'hasAccountFeature', 'isTemplateEditorMode']),
      textShadow: {
        get() {
          return this.getValueOf('textShadow.type');
        },
        set(value) {
          this.setValueOf('textShadow.type', value);
        },
      },
      textRotation: {
        get() {
          return Number(this.getValueOf('desktop.textRotation'));
        },
        set(v) {
          this.setValueOf('desktop.textRotation', v);
        },
      },
      fontSize: {
        get() {
          return Number(this.getValueOf('mobile.fontMultiplier'));
        },
        set(v) {
          this.setValueOf('mobile.fontMultiplier', v);
        },
      },
      alignment: {
        get() {
          return this.getValueOf('mobile.textAlign');
        },
        set(value) {
          this.setValueOf('mobile.textAlign', value);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      paddingKeys() {
        return [
          { name: 'top', value: this.smartGetValueOf('$device.padding.top') },
          { name: 'right', value: this.smartGetValueOf('$device.padding.right') },
          { name: 'bottom', value: this.smartGetValueOf('$device.padding.bottom') },
          { name: 'left', value: this.smartGetValueOf('$device.padding.left') },
        ];
      },
      marginKeys() {
        return [
          { name: 'top', value: this.smartGetValueOf('$device.margin.top') },
          { name: 'right', value: this.smartGetValueOf('$device.margin.right') },
          { name: 'bottom', value: this.smartGetValueOf('$device.margin.bottom') },
          { name: 'left', value: this.smartGetValueOf('$device.margin.left') },
        ];
      },
      allKeysToggled() {
        return this.getValueOf('padding.allSides');
      },
      allMarginKeysToggled() {
        return this.getValueOf('margin.allSides');
      },
      paddingPlaceholders() {
        if (this.mobilePreview) {
          return [
            this.getValueOf('desktop.padding.top'),
            this.getValueOf('desktop.padding.right'),
            this.getValueOf('desktop.padding.bottom'),
            this.getValueOf('desktop.padding.left'),
          ];
        }
        return null;
      },
      marginPlaceholders() {
        if (this.mobilePreview) {
          return [
            this.getValueOf('desktop.margin.top'),
            this.getValueOf('desktop.margin.right'),
            this.getValueOf('desktop.margin.bottom'),
            this.getValueOf('desktop.margin.left'),
          ];
        }
        return null;
      },
      isSmartHeadlineFeatureEnabled() {
        return this.hasAccountFeature(SMART_HEADLINE_GENERATOR) && !this.isTemplateEditorMode;
      },
    },
    methods: {
      handlePaddingAllSideChange(isToggled) {
        this.setValueOf('padding.allSides', isToggled);
      },
      handlePaddingChange({ key, value, allSides }) {
        if (allSides) {
          const keys = ['top', 'right', 'bottom', 'left'];
          keys.forEach((key) => {
            this.smartSetValueOf(`$device.padding.${key}`, value);
          });
        } else {
          this.smartSetValueOf(`$device.padding.${key}`, value);
        }
      },
      handleMarginAllSideChange(isToggled) {
        this.setValueOf('margin.allSides', isToggled);
      },
      handleMarginChange({ key, value, allSides }) {
        if (allSides) {
          const keys = ['top', 'right', 'bottom', 'left'];
          keys.forEach((key) => {
            this.smartSetValueOf(`$device.margin.${key}`, value);
          });
        } else {
          this.smartSetValueOf(`$device.margin.${key}`, value);
        }
      },
    },
  };
</script>
